import './main.css';
import { Elm } from './Main.elm';
import * as serviceWorker from './serviceWorker';

var app = Elm.Main.init({
  node: document.getElementById("kanaMatchRoot")
});

app.ports.sendMessage.subscribe(function (message) {
  if (message === "jumpToTop") {
    window.scrollTo(0, 0);
  }
});

// Scrolling Logic for the dynamic header:

var _window = window;
var _document = _window.document;
var _body = _document.body;
var _html = _document.documentElement;

var scrollTimer = null;
var lastScrollFireTime = 0;
var minScrollTime = 200;
document.body.onscroll = function (event) {
  event.returnValue = false;

  if (scrollTimer) { } else {
    var now = new Date().getTime();
    if (now - lastScrollFireTime > (3 * minScrollTime)) {
      processScrollOrResize();
      lastScrollFireTime = now;
    }
    scrollTimer = setTimeout(function () {
      scrollTimer = null;
      lastScrollFireTime = new Date().getTime();
      processScrollOrResize();
    }, minScrollTime);
  }
};

var processScrollOrResize = function () {
  var screenData = {
    scrollTop: parseInt(window.pageYOffset || _html.scrollTop || _body.scrollTop || 0),
    pageHeight: parseInt(Math.max(document.body.scrollHeight, document.body.offsetHeight, _html.clientHeight, _html.scrollHeight, _html.offsetHeight)),
    viewportHeight: parseInt(_html.clientHeight),
    viewportWidth: parseInt(_html.clientWidth),
  };

  var Viewport = {
    scene:
    {
      height: screenData.pageHeight
      , width: screenData.viewportWidth
    }
    , viewport:
    {

      height: screenData.viewportHeight
      , width: screenData.viewportWidth
      , x: 0
      , y: screenData.scrollTop
    }
  };

  app.ports.messageReceiver.send(Viewport);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
